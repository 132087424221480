import './App.css';

function App() {
  return (
    <div className="App">
      Contact Email : feanlau@outlook.com
      <header className="App-header">
        {/* <img className="App-logo" alt="logo" /> */}
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        Contact Email : feanlau@outlook.com
      </header>
    </div>
  );
}

export default App;